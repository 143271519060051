import styled from 'styled-components';
import { Box, Flex } from '@latitude/box';
import {
  MARGIN,
  BREAKPOINT,
  COLOR,
  PADDING
} from '@latitude/core/utils/constants';

export const StyledCalculatorFlex = styled(Flex)`
  flex-direction: column;
  @media (min-width: ${BREAKPOINT.LG}) {
    flex-direction: row;
  }
`;

export const StyledDisclaimerBox = styled(Box)`
  padding: ${PADDING.P0} ${PADDING.P48} ${PADDING.P24} ${PADDING.P48};
  background-color: ${COLOR.BLUE_DEEP};
  width: 100vw;
  margin-top: 0;
  margin-left: calc(-50vw + 50%);

  a.link.link--no-style {
    color: ${COLOR.BLUE};
    text-decoration: underline;

    &.link--white {
      color: ${COLOR.WHITE};
    }
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    background: transparent;
    width: 100%;
    margin-top: ${MARGIN.M40};
    margin-left: 0;
    padding-left: 100px;
    padding-right: 100px;
  }
`;

export const StyledDisclaimerKbBox = styled(Box)`
  padding: ${PADDING.P0} ${PADDING.P48} ${PADDING.P24} ${PADDING.P48};
  background-color: ${COLOR.GREY4};
  width: 100vw;
  margin-top: 0;
  margin-left: calc(-50vw + 50%);

  a.link.link--no-style {
    color: ${COLOR.BLACK};
    text-decoration: underline;

    &.link--white {
      color: ${COLOR.WHITE};
    }
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    background: transparent;
    width: 100%;
    margin-top: ${MARGIN.M40};
    margin-left: 0;
    padding-left: 100px;
    padding-right: 100px;
  }
`;
