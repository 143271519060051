/* eslint-disable no-script-url */
import React, { Fragment, useState, useContext } from 'react';
import { AnalyticsContext, trackEvent } from 'latitude-analytics';
import styled from 'styled-components';
// import { Box } from '@latitude/box';
// import SvgInline from '@latitude/svg-inline';
import { Text, Bold } from '@latitude/text';
import { Tel } from '@latitude/tel';
import {
  COLOR,
  ALIGN,
  MARGIN,
  BREAKPOINT
} from '@latitude/core/utils/constants';
import { Link } from '@latitude/link';
import { Box } from '../Box/Box';
import SvgInline from '../SvgInline/SvgInline';
import RadioBoxes from '../RadioBoxes/RadioBoxes';
import Modal from '../Modal/Modal';
import {
  LOAN_PAY_FREQUENCY,
  LOAN_TYPES,
  CALCULATOR_TRACKING
} from '../../utils/constants';
import { directTrackLink } from '../../utils/analyticsUtil';
import {
  customLinkNamesPL,
  customLinkNamesCL,
  getCustomLinkEventName
} from './LoanCalculator.analytics';

const StyledSvg = styled(SvgInline)`
  width: 20px;
  height: 20px;
  position: relative;
  top: 5px;
  margin-left: 5px;
`;

const StyledBox = styled(Box)`
  margin: ${MARGIN.M16} ${MARGIN.M24} ${MARGIN.M24};

  @media (min-width: ${BREAKPOINT.LG}) {
    margin: 0 ${MARGIN.M48} ${MARGIN.M16} ${MARGIN.M48};
  }
`;

const repaymentPeriodText = {
  [LOAN_PAY_FREQUENCY.WEEKLY]: 'week',
  [LOAN_PAY_FREQUENCY.FORTNIGHTLY]: 'fortnight',
  [LOAN_PAY_FREQUENCY.MONTHLY]: 'month'
};

const ExtraRepayments = ({
  loanType,
  salesMode,
  repaymentPeriod,
  extraRepayments,
  setExtraRepayments,
  applyCTATrackProductId
}) => {
  const [analytics] = useContext(AnalyticsContext);
  // loan type variants
  const isLoanTypePL = loanType === LOAN_TYPES.PL;
  const customLinkNames = isLoanTypePL ? customLinkNamesPL : customLinkNamesCL;
  const tel = '0800 549 454'
  const radioBtnId = isLoanTypePL ? 'extraRepaymentsPL' : 'extraRepaymentsCL';

  // states and hooks
  const [showModal, setShowModal] = useState(false);

  // event handlers
  const handleExtraRepaymentOnChange = e => {
    const newExtraValue = e.target.value;
    setExtraRepayments(parseInt(newExtraValue, 10));

    // trigger analytics call
    // eslint-disable-next-line no-unused-expressions
    !salesMode &&
      directTrackLink(
        true,
        'o',
        getCustomLinkEventName(
          customLinkNames.EXTRA_PAYMENT_CHANGE,
          newExtraValue
        )
      );

    /*
     * Tealium tracking
     */
    if (trackEvent) {
      trackEvent(analytics, {
        category: CALCULATOR_TRACKING.CATEGORY,
        action: CALCULATOR_TRACKING.ACTION,
        label: 'Pay it faster to save',
        location: CALCULATOR_TRACKING.LOCATION,
        value: newExtraValue
      });
    }
  };

  return (
    <Box>
      <StyledBox>
        <RadioBoxes
          inverted
          labelText={
            <Text align={ALIGN.CENTER} color={COLOR.WHITE}>
              <Bold>
                Pay it faster every {repaymentPeriodText[repaymentPeriod]} to
                save
              </Bold>
              <Link
                noStyle
                onClick={() => setShowModal(true)}
                href="#"
                trackEventData={{
                  category: CALCULATOR_TRACKING.CATEGORY,
                  action: CALCULATOR_TRACKING.ACTION,
                  label: 'i - pay it faster',
                  location: 'Calculate your repayments'
                }}
                trackProductId={applyCTATrackProductId}
              >
                <StyledSvg name="info-block-outline" fillWhite />
              </Link>
            </Text>
          }
          name={radioBtnId}
          onChange={handleExtraRepaymentOnChange}
          values={[
            { value: 0, text: 'No Extra' },
            { value: 25, text: '+$25' },
            { value: 50, text: '+$50' },
            { value: 100, text: '+100' }
          ]}
          selectedValue={extraRepayments}
          equalWidths
        />
      </StyledBox>
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        title="Pay it faster"
        content={
          <Fragment>
            <Text>
              <Bold>Would I be locked into paying more each month?</Bold>
            </Text>
            <p>
              Absolutely not. You can revert to your minimum repayment at any
              time.
            </p>
            <Text>
              <Bold>What if I only want to pay extra occasionally?</Bold>
            </Text>
            <p>
              At Latitude, we offer a range of flexible payment options. So if
              you&apos;d like to pick and choose when you pay extra, you can do
              so – just give us a call on&nbsp;<Tel no={tel} css='color:#000 !important; border-bottom: 0px #000 !important' />&nbsp;to find out more.
            </p>
            <Text>
              <Bold>What if I repay my loan super fast?</Bold>
            </Text>
            <p>
              That&apos;s ok too, as we don&apos;t charge any early repayment
              fees.
            </p>
          </Fragment>
        }
        ariaHideApp={false}
      />
    </Box>
  );
};

export default ExtraRepayments;
