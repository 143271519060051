// @flow
import React, { ChangeEvent } from 'react';
import styled, { type ReactComponentStyled } from 'styled-components';
import classnames from 'classnames';
import { BREAKPOINT } from '@latitude/core/utils/constants';
import { parseContent } from '../../utils/helpers';
import './_radio-boxes-kb.scss';

type StyledProps = {
  /**  The total number of radiobox labels inside the radiobox */
  noOfItems: string,
  /**  Whether or not the widths of each radiobox label should be equal or not */
  equalWidths?: boolean
};

type RadioBoxValueItem = {
  text: string,
  value: string
};

type Props = {
  /**  Name of input */
  name: string,
  /**  Label of input, displayed above */
  labelText?: string,
  /**  List of value data items */
  values: RadioBoxValueItem[],
  /**  Value of selected item */
  selectedValue?: string | number,
  /**  If to show error message */
  showError?: boolean,
  /**  Error message that should be displayed when showError is true */
  showErrorMessage?: string,
  /**  CSS classes */
  className?: string,
  /**  onClick handler, use to get value changes */
  onClick: (e: ChangeEvent<HTMLInputElement>) => void,
  /**  Inverted boolean to control whether or not to display the inverted colors variant */
  inverted?: boolean,
  /**  Whether or not the widths of each radiobox label should be equal or not */
  equalWidths?: boolean
};

const component = 'radio-box';
const StyledLabel: ReactComponentStyled<StyledProps> = styled.label`
  flex-basis: ${({ equalWidths, noOfItems }: StyledProps) =>
    equalWidths ? `${100 / Number(noOfItems)}%` : 'auto'};
  width: ${({ equalWidths, noOfItems }: StyledProps) =>
    equalWidths ? `${100 / Number(noOfItems)}%` : 'auto'};
  padding: ${({ equalWidths }: StyledProps) =>
    equalWidths ? `0.75rem 0px` : '0.75rem'};

  @media (max-width: ${BREAKPOINT.SM}) {
    padding: ${({ equalWidths }: StyledProps) =>
      equalWidths ? `1rem 0px` : '1rem'};
  }
`;

const RadioBoxes = (props: Props) => {
  const {
    labelText,
    values,
    selectedValue,
    name,
    onClick,
    showError,
    showErrorMessage,
    className,
    inverted,
    equalWidths
  } = props;

  const labelErrorClass = showError ? `${component}__label-error` : '';

  return (
    <div className="input-wrap">
      {labelText && (
        /* eslint-disable-next-line */
        <label
          className={classnames(
            `${component}__group-label`,
            labelErrorClass,
            inverted ? `${component}__label-inverted` : ''
          )}
        >
          {parseContent(labelText)}
        </label>
      )}
      <div
        className={classnames(
          `radio-boxes`,
          inverted
            ? 'radio-boxes__inverted-color'
            : 'radio-boxes__default-color',
          className
        )}
      >
        {values.map((item: RadioBoxValueItem, index: number) => {
          let labelClass = `${component}__label`;
          const generatedId = `${component}-${name}-${index}`;
          const checked =
            selectedValue !== undefined &&
            selectedValue.toString() === item.value.toString();
          if (checked) {
            labelClass += ' active';
          }
          return (
            <StyledLabel
              id={`${generatedId}-label`}
              htmlFor={generatedId}
              key={index}
              className={labelClass}
              equalWidths={equalWidths}
              noOfItems={values.length}
            >
              <input
                id={generatedId}
                type="radio"
                className={`${component}__input`}
                value={item.value}
                checked={checked}
                onClick={onClick}
                name={name}
              />
              <span className={`${component}__text`}>
                {parseContent(item.text)}
              </span>
            </StyledLabel>
          );
        })}
      </div>
      {showError && (
        <div className={`${component}__error-container`}>
          <div className={`${component}__error-message`}>
            {showErrorMessage}
          </div>
        </div>
      )}
    </div>
  );
};

export default RadioBoxes;
