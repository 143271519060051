import React from 'react';
import ReactHTMLParser from 'react-html-parser';
import {
  ALIGN,
  BREAKPOINT,
  BUTTON_STYLE,
  COLOR,
  MARGIN,
  PADDING,
  POSITION
} from '@latitude/core/utils/constants';
import { Heading2 } from '@latitude/heading';
import { Box } from '@latitude/box';
import { Link } from '@latitude/link';
import { Metadata } from '@latitude/metadata';
import { BrandedCallout } from '@latitude/callout';
import { Tel } from '@latitude/tel';
// import { StickyCta } from '@latitude/sticky-cta';
import { StickyNavigation } from '@latitude/sticky-navigation';
import HeroBranded from '../../components/HeroBranded/HeroBranded';
import ImportantInformation from '../../components/ImportantInformation/ImportantInformation';
import Layout from '../../components/layout-kiwibank';
import Lframe from '../../components/Lframe/Lframe';
import MobileAppInstallPrompts from '../../components/MobileAppInstallPrompts/MobileAppInstallPrompts';
import PageData from '../../data/pages/calculators-and-tools/personal-loan-repayment-calculator-kb.json';
import PersonalLoanCalculatorKiwibank from '../../components/LoanCalculator/PersonalLoanCalculatorKiwibank';
import withStickyState from '../../hoc/withStickyState';
import { SOFT_QUOTE_KIWIBANK_URL } from '../../utils/softQuoteUtil';
import HowToApplySection from '../../components/HowToApplySection/HowToApplySectionKiwibank';
import data from '../../data/json/how-to-apply.json';
import FooterKiwibank from '../personal-loans/_footer';

const PLCalculatorPage = props => (
  <Layout location={props.location} noFooter>
    <main className="navigation-spacer personal-loan-calculator-page">
      <Box backgroundColor={COLOR.GREY6}>
        <MobileAppInstallPrompts />
        <Metadata
          title={PageData.title}
          description={PageData.description}
          canonical={PageData.path}
        />
        <Lframe hasMobileStickyCta personalLoansKiwibank />
        <Box id="hero">
        <HeroBranded
            id="hero"
            css={`
              br {
                display: none;
              }
              @media (min-width: ${BREAKPOINT.XL}) {
                br {
                  display: block;
                }
                .HeroContent {
                  padding-bottom: 80px;
                }
              }
            `}
            title={
              <>
                Personal Loan<br /> Repayment Calculator
              </>
            }
            text={
              <>
                Let&apos;s see how much your repayments could be with a<br /> Personal Loan.
              </>
            }
          />
        </Box>
        {/* <div className="d-lg-none d-xl-none">
          <StickyCta
            href={SOFT_QUOTE_KIWIBANK_URL}
            trackId="sticky-cta-check-my-eligibility"
            text="Check my eligibility"
            onClick={() => {
              // force link to open in the same tab
              if (typeof window !== 'undefined')
                window.location = SOFT_QUOTE_KIWIBANK_URL;
            }}
          />
        </div> */}
        {/* <div className="d-none d-lg-block" css="position:relative; z-index:10;"> */}
        <div className="d-none d-lg-block">
          <StickyNavigation
            items={PageData.nav}
            phoneNumber={PageData.content.phoneNumber}
            isSticky={props.isNavSticky}
            // ctaHref={SOFT_QUOTE_KIWIBANK_URL}
            // ctaText="Check my eligibility"
            onStickyNavStateChange={props.handleStickyNavStateChange}
            offsetElem="[data-sticky-navigation-offset]"
            trackId="sticky-nav-check-my-eligibility"
            trackEventData={{
              category: 'cta',
              action: 'quote-link',
              label: 'Check my eligibility',
              location: 'Sticky Navigation'
            }}
            trackProductId={['PLNZLF-WEB']}
          />
        </div>
        <Box
          id={PageData.nav[0].anchor}
          backgroundColor={COLOR.GREY10}
          position={POSITION.RELATIVE}
        >
          <Box padding={`${PADDING.P48} 15px 0 15px`}>
            <Heading2 color={COLOR.BLACK} align={ALIGN.CENTER}>
              Calculate your repayments
            </Heading2>
          </Box>
          <Box isResponsive margin={`${MARGIN.M0} ${MARGIN.MAUTO}`}>
            <PersonalLoanCalculatorKiwibank
              applyUrl={SOFT_QUOTE_KIWIBANK_URL}
              applyCTATrackProductId={['PLNZLF-WEB']}
            />
          </Box>
        </Box>
        <Box id={PageData.nav[1].anchor}>
          <BrandedCallout
            moreVerticalPadding
            hasConfettiBackground
            confettiBackground={PageData.content.callOut.bgImage}
            confettiBackgroundColor={COLOR.GREY6}
            confettiBackgroundRepeat="repeat"
            confettiBackgroundPos="center 35%"
            heading={PageData.content.callOut.title}
            line1={ReactHTMLParser(PageData.content.callOut.content)}
            cta={
              <Box>
                <Link
                  button={BUTTON_STYLE.PRIMARY}
                  href={PageData.content.callOut.ctaUrl}
                  trackId="callout-find-out-more"
                  trackEventData={{
                    category: 'cta',
                    action: 'quote-link',
                    location: "Want more information on our Personal Loans?"
                  }}
                  trackProductId={['PLNZLF-WEB']}
                  style={{ marginTop: 24 }}
                >
                  {PageData.content.callOut.ctaLabel}
                </Link>
              </Box>
            }
          />
        </Box>
        <HowToApplySection
          kiwibank
          data={props.data || data.dataKiwibank}
        />
        <BrandedCallout
          moreVerticalPadding
          hasConfettiBackground
          confettiBackground={PageData.content.callOut.bgImage}
          confettiBackgroundColor={COLOR.GREY6}
          confettiBackgroundRepeat="repeat"
          confettiBackgroundPos="center 35%"
          line1={
            <React.Fragment>
              <strong>
                Call our Personal Loan specialists on{' '}
                <Tel no="0800 549 454" color={COLOR.BLACK} />
              </strong>
            </React.Fragment>
          }
          line2={
            <React.Fragment>
              <span>Mon to Fri 9:00am - 6:30pm (NZST). Weekends closed.</span>
            </React.Fragment>
          }
          // cta={
          //   <Box width="210px">
          //     <Link
          //       button={BUTTON_STYLE.PRIMARY}
          //       href={SOFT_QUOTE_KIWIBANK_URL}
          //       trackId="callout-check-my-eligibility"
          //       className="w-auto"
          //       target="_self"
          //       trackEventData={{
          //         category: 'cta',
          //         action: 'quote-link',
          //         location: "We're here to help"
          //       }}
          //       trackProductId={['PLNZLF-WEB']}
          //       style={{ marginTop: 24 }}
          //     >
          //       Check my eligibility
          //     </Link>
          //   </Box>
          // }
        />
        <ImportantInformation
          data={require('../../data/pages/calculators-and-tools/personal-loan-repayment-calculator-kb.json')}
          sectionOneColummClasses="col-10 offset-1"
        />
      </Box>
    </main>
    <FooterKiwibank />
  </Layout>
);

export default withStickyState(PLCalculatorPage);
