import React from 'react';
import styled from 'styled-components';
import AnimatedNumber from 'react-animated-number';
import {
  ALIGN,
  ALIGN_ITEMS,
  BREAKPOINT,
  // BUTTON_STYLE,
  COLOR,
  FLEX_DIRECTION,
  JUSTIFY_CONTENT,
  MARGIN,
  PADDING
} from '@latitude/core/utils/constants';
import { Box, Flex } from '@latitude/box';
// import { Link } from '@latitude/link';
import { Text } from '@latitude/text';
import { Heading4 } from '@latitude/heading';
import {
  LOAN_PAY_FREQUENCY,
  LOAN_TYPES,
  // PL_MINIMUM_AMOUNT,
  // CL_MINIMUM_AMOUNT,
  // PL_DEFAULT_LOAN_TERM,
  // CL_DEFAULT_LOAN_TERM
} from '../../utils/constants';
// import Button from '../Button/Button';
import { formatAsCurrency } from '../../utils';
import ExtraRepayments from './ExtraRepayments';

const repaymentPeriodText = {
  [LOAN_PAY_FREQUENCY.WEEKLY]: 'weekly',
  [LOAN_PAY_FREQUENCY.FORTNIGHTLY]: 'fortnightly',
  [LOAN_PAY_FREQUENCY.MONTHLY]: 'monthly'
};

const animatedNumbersDuration = 300;
const styledArrowW = 36;
const styledArrowH = 22;
const styledArrowBg = COLOR.GREY10;

const StyledDisclaimerText = styled.p`
  font-size: 80px;
  line-height: 88px;
  margin-bottom: 0;
  color:#fff;
  text-align: center;
  margin-top: 24px;

  @media (min-width: ${BREAKPOINT.LG}) {
    margin-bottom: 24px;
    font-size: 94px;
    line-height: 70px;
    letter-spacing: -2.38px;
  }
`;

const StyledCalculatorDisplay = styled(Flex)`
  position: relative;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  min-height: 300px;
  margin-top: ${MARGIN.M32};

  &::before {
    content: '';
    position: absolute;
    display: block;
    height: 1px;
    width: 1px;
    font-size: 0;
    line-height: 0;
    margin-left: -${styledArrowW / 2}px;
    left: 50%;
    top: -1px;
    border: ${styledArrowH}px solid transparent;
    border-left-width: ${styledArrowW / 2}px;
    border-right-width: ${styledArrowW / 2}px;
    border-top-color: ${styledArrowBg};
    border-bottom: none;
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    margin-left: ${MARGIN.M32};
    width: 50%;
    margin-top: 0;

    &::before {
      display: none;
    }
  }
`;

const StyledFlex = styled(Flex)`
  flex-grow: 1;
`;

const AmountsSavedFlex = styled(Flex)`
  flex-grow: 1;

  &::before {
    content: '';
    position: relative;
    display: block;
    height: 1px;
    width: 1px;
    font-size: 0;
    line-height: 0;
    margin-left: -${styledArrowW / 2}px;
    left: 50%;
    border: ${styledArrowH}px solid transparent;
    border-left-width: ${styledArrowW / 2}px;
    border-right-width: ${styledArrowW / 2}px;
    border-top-color: ${COLOR.BLACK};
    border-bottom: none;
  }
`;

const StyledCalculatorTotalsBox = styled(Box)`
  margin: ${MARGIN.M48} 0 ${MARGIN.M16};
  @media (min-width: ${BREAKPOINT.LG}) {
    margin: 0;
  }
`;

const MobileTimeSavedHeading = styled(Heading4)`
  display: block;
  font-size: 20px;
  line-height: 32px;
  @media (min-width: ${BREAKPOINT.SM}) {
    display: none;
  }
`;

const DesktopTimeSavedHeading = styled(Heading4)`
  display: none;
  font-size: 20px;
  line-height: 32px;
  @media (min-width: ${BREAKPOINT.SM}) {
    display: block;
  }
`;

// const StyledApplyNowBox = styled(Box)`
//   width: 250px;
//   margin: ${MARGIN.M16} 0 ${MARGIN.M24};

//   @media (min-width: ${BREAKPOINT.LG}) {
//     margin: ${MARGIN.M16} 0;
//   }
// `;

const StyledHeading4 = styled(Heading4)`
  font-size: 20px;
  line-height: 32px;
`;

// repayment panel intro text
const repaymentPanelIntroPL = repaymentText => (
  <Box padding={`${PADDING.P24} ${PADDING.P48} 0 ${PADDING.P48}`}>
    <StyledHeading4 align={ALIGN.CENTER} color={COLOR.WHITE}>
      Your estimated {repaymentText} repayment including establishment fee
    </StyledHeading4>
  </Box>
);
const repaymentPanelIntroCL = repaymentText => (
  <Box padding={`${PADDING.P24} ${PADDING.P48} 0 ${PADDING.P48}`}>
    <StyledHeading4 align={ALIGN.CENTER} color={COLOR.WHITE}>
      Your estimated {repaymentText} repayment including establishment fee
    </StyledHeading4>
  </Box>
);

const CalculatorTotals = ({
  loanType,
  salesMode,
  repaymentPeriod,
  repaymentAmount,
  totalInterestPaid,
  totalAmountPaid,
  timeSavedMonths,
  timeSavedYears,
  interestSaved,
  extraRepayments,
  setExtraRepayments,
  // applyUrl,
  // applyCTATrackId,
  applyCTATrackProductId,
  // userInputLoanAmount,
  // userInputRepaymentYears,
  renderIntro = loanType === LOAN_TYPES.PL
    ? repaymentPanelIntroPL
    : repaymentPanelIntroCL
}) => {
  // const defaultLoanAmount =
  //   loanType === LOAN_TYPES.PL ? PL_MINIMUM_AMOUNT : CL_MINIMUM_AMOUNT;

  // const defaultRepaymentYears =
  //   loanType === LOAN_TYPES.PL ? PL_DEFAULT_LOAN_TERM : CL_DEFAULT_LOAN_TERM;

  const TimeSavedSection = (yearsText, monthsText) => (
    <>
      {timeSavedYears !== 0 && (
        <>
          <AnimatedNumber
            value={timeSavedYears}
            duration={animatedNumbersDuration}
            stepPrecision={0}
          />
          {timeSavedYears > 1 ? ` ${yearsText}s` : ` ${yearsText}`}
        </>
      )}
      {timeSavedMonths !== 0 && (
        <>
          {timeSavedYears ? ', ' : null}
          <AnimatedNumber
            value={timeSavedMonths}
            duration={animatedNumbersDuration}
            stepPrecision={0}
          />
          {timeSavedMonths > 1 ? ` ${monthsText}s` : ` ${monthsText}`}
        </>
      )}
    </>
  );

  return (
    <StyledCalculatorDisplay flexDirection={FLEX_DIRECTION.COLUMN}>
      <StyledFlex
        backgroundColor={COLOR.BLACK}
        justifyContent={JUSTIFY_CONTENT.CENTER}
        flexDirection={FLEX_DIRECTION.COLUMN}
      >
        <StyledCalculatorTotalsBox>
          {renderIntro(repaymentPeriodText[repaymentPeriod])}
          <StyledDisclaimerText align={ALIGN.CENTER} color={COLOR.WHITE}>
            <AnimatedNumber
              value={repaymentAmount}
              duration={animatedNumbersDuration}
              stepPrecision={0}
              formatValue={n => formatAsCurrency(n) || '$0'}
            />
          </StyledDisclaimerText>
        </StyledCalculatorTotalsBox>
        <ExtraRepayments
          loanType={loanType}
          salesMode={salesMode}
          repaymentPeriod={repaymentPeriod}
          extraRepayments={extraRepayments}
          setExtraRepayments={setExtraRepayments}
          applyCTATrackProductId={applyCTATrackProductId}
        />
      </StyledFlex>
      <AmountsSavedFlex
        backgroundColor={COLOR.GREY4}
        flexDirection={FLEX_DIRECTION.COLUMN}
      >
        <Flex alignItems={ALIGN_ITEMS.CENTER} paddingTop={PADDING.P8}>
          <Box width="50%" marginRight={MARGIN.M8}>
            <Text align={ALIGN.RIGHT} color={COLOR.BLACK}>
              Total interest paid
            </Text>
          </Box>
          <Box width="50%" marginLeft={MARGIN.M8}>
            <StyledHeading4 marginBottom={0} color={COLOR.BLACK}>
              <AnimatedNumber
                value={totalInterestPaid}
                duration={animatedNumbersDuration}
                stepPrecision={0}
                formatValue={n => formatAsCurrency(n) || '$0'}
              />
            </StyledHeading4>
          </Box>
        </Flex>
        <Flex alignItems={ALIGN_ITEMS.CENTER}>
          <Box width="50%" marginRight={MARGIN.M8}>
            <Text align={ALIGN.RIGHT} color={COLOR.BLACK}>
              Total amount paid
            </Text>
          </Box>
          <Box width="50%" marginLeft={MARGIN.M8}>
            <StyledHeading4 marginBottom={0} color={COLOR.BLACK}>
              <AnimatedNumber
                value={totalAmountPaid}
                duration={animatedNumbersDuration}
                stepPrecision={0}
                formatValue={n => formatAsCurrency(n) || '$0'}
              />
            </StyledHeading4>
          </Box>
        </Flex>
        <Flex alignItems={ALIGN_ITEMS.CENTER}>
          <Box width="50%" marginRight={MARGIN.M8}>
            <Text align={ALIGN.RIGHT} color={COLOR.BLACK}>
              Interest saved
            </Text>
          </Box>
          <Box width="50%" marginLeft={MARGIN.M8}>
            <StyledHeading4 marginBottom={0} color={COLOR.BLACK}>
              <AnimatedNumber
                value={interestSaved}
                duration={animatedNumbersDuration}
                stepPrecision={0}
                formatValue={n => formatAsCurrency(n) || '$0'}
              />
            </StyledHeading4>
          </Box>
        </Flex>
        <Flex alignItems={ALIGN_ITEMS.CENTER}>
          <Box width="50%" marginRight={MARGIN.M8}>
            <Text align={ALIGN.RIGHT} color={COLOR.BLACK}>
              Time saved
            </Text>
          </Box>
          <Box width="50%" marginLeft={MARGIN.M8}>
            <>
              {timeSavedMonths === 0 && timeSavedYears === 0 ? (
                <>
                  <MobileTimeSavedHeading marginBottom={0} color={COLOR.BLACK}>
                    0 yrs, 0 mths
                  </MobileTimeSavedHeading>
                  <DesktopTimeSavedHeading marginBottom={0} color={COLOR.BLACK}>
                    0 years, 0 months
                  </DesktopTimeSavedHeading>
                </>
              ) : (
                <>
                  <MobileTimeSavedHeading marginBottom={0} color={COLOR.BLACK}>
                    {TimeSavedSection('yr', 'mth')}
                  </MobileTimeSavedHeading>
                  <DesktopTimeSavedHeading marginBottom={0} color={COLOR.BLACK}>
                    {TimeSavedSection('year', 'month')}
                  </DesktopTimeSavedHeading>
                </>
              )}
            </>
          </Box>
        </Flex>
      </AmountsSavedFlex>
      <Flex
        backgroundColor={COLOR.GREY4}
        justifyContent={JUSTIFY_CONTENT.CENTER}
        paddingTop={PADDING.P24}
      >
        {/* {!salesMode && (
          <StyledApplyNowBox>
            <Link
              button={BUTTON_STYLE.PRIMARY}
              href="#"
              trackId={applyCTATrackId}
              trackEventData={{
                category: 'cta',
                action: 'quote-link',
                location: 'Calculate your repayments'
              }}
              trackProductId={applyCTATrackProductId}
              target="_self"
              onClick={() => {
                if (typeof window !== 'undefined')
                  window.location = `${applyUrl}${`?amount=${userInputLoanAmount ||
                    defaultLoanAmount}&period=${userInputRepaymentYears ||
                    defaultRepaymentYears}`}`;
              }}
              css="cursor:pointer;"
            >
              Check my eligibility
            </Link>
          </StyledApplyNowBox>
        )} */}
      </Flex>
    </StyledCalculatorDisplay>
  );
};

export default CalculatorTotals;
