import React from 'react';
import styled from 'styled-components';
import {
  MARGIN,
  PADDING,
  FONT_SIZE,
  BREAKPOINT,
  FLEX_DIRECTION,
  DISPLAY,
  JUSTIFY_CONTENT,
  COLOR,
  ALIGN,
  LINE_HEIGHT,
  FLEX_WRAP
} from '@latitude/core/utils/constants';
import { Link } from '../../components/Link/Link';

const StyledFooter = styled.footer`
  padding: ${PADDING.P24} 0 ${PADDING.P32};
  background-color: ${COLOR.GREY5};
  font-size: ${FONT_SIZE.SMALL};

  @media (min-width: ${BREAKPOINT.LG}) {
    padding-top: ${PADDING.P32};
  }
`;

const StyledDisclaimerText = styled.p`
  text-align: ${ALIGN.CENTER};
  line-height: ${LINE_HEIGHT.TIGHT.SMALL};
  margin: 0;
  @media (min-width: ${BREAKPOINT.LG}) {
    margin-bottom: 7px;
    text-align: ${ALIGN.LEFT};
  }
`;

const StyledFooterInner = styled.div`
  justify-content: ${JUSTIFY_CONTENT.CENTER};
  @media (min-width: ${BREAKPOINT.LG}) {
    display: ${DISPLAY.FLEX};
    flex-direction: ${FLEX_DIRECTION.ROW};
  }
`;

const StyledUl = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-bottom: ${MARGIN.M16};
  display: ${DISPLAY.FLEX};
  flex-wrap: ${FLEX_WRAP.WRAP};
  justify-content: ${JUSTIFY_CONTENT.CENTER};

  li {
    padding: 0 ${PADDING.P8};
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    margin-top: 0;
    justify-content: ${JUSTIFY_CONTENT.FLEX_START};

    li {
      padding: 0 ${PADDING.P16} 0 0;
    }
  }
`;

const FooterKiwibank = () => (
  <StyledFooter>
    <div className="container">
      <StyledFooterInner>
        <div>
          <StyledUl>
            <li>
              <Link href="https://assets.latitudefinancial.com/legals/terms-conditions/latitude-for-kiwibank-terms.pdf">
                Terms & Conditions
              </Link>
            </li>
            <li>
              <Link href="https://assets.latitudefinancial.com/legals/product-disclosure-statements/nz/kiwibank-fixed-rate-pl-disclosure-statement.pdf">
                Loan Schedule
              </Link>
            </li>
            <li>
              <Link href="/privacy/">
                Privacy & Credit Reporting Policy
              </Link>
            </li>
            <li>
              <Link href="https://assets.latitudefinancial.com/legals/product-disclosure-statements/nz/kiwibank-financial-adviser-disclosure-statement.pdf">Prescribed Statement Disclosure</Link>
            </li>
          </StyledUl>
          <StyledDisclaimerText>
            Personal Loans are provided by Latitude Financial Services,
            8 Tangihua Street, Auckland, 1010 New Zealand.
          </StyledDisclaimerText>
        </div>
      </StyledFooterInner>
    </div>
  </StyledFooter>
);
export default FooterKiwibank;
