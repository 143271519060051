/**
 * Adds native banners on top of page on mobile devices that prompts to install Latitude app if it's not already installed
 * The styling of the prompt is out of our control as it's a native feature.
 * 
 * More information about smart banners:
 * 
 * iOS
 * https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariWebContent/PromotingAppswithAppBanners/PromotingAppswithAppBanners.html
 * 
 * Android
 * https://developers.google.com/web/fundamentals/app-install-banners/native
 */

import React from 'react';
import Helmet from 'react-helmet';

const MobileAppInstallPrompt = () => 
  <Helmet>
    <meta name="apple-itunes-app" content="app-id=1373059866" />
    <link rel="manifest" href="/manifest.json" />
  </Helmet>
;

export default MobileAppInstallPrompt;
