// @flow

const trackingPrefixPL = 'gemf nz:loans:personal-loan:calculator:';
const trackingPrefixCL = 'gemf nz:loans:car-loan:calculator:';

// Custom link event names for calculator field interactions
export const customLinkNamesPL = Object.freeze({
  AMOUNT_CHANGE: `${trackingPrefixPL}loan-amount-change`,
  PERIOD_CHANGE: `${trackingPrefixPL}payment-period-change`,
  RATE_TYPE_CHANGE: `${trackingPrefixPL}rate-type-change`,
  RATE_CHANGE: `${trackingPrefixPL}interest-rate-change`,
  TERM_CHANGE: `${trackingPrefixPL}loan-term-change`,
  EXTRA_PAYMENT_CHANGE: `${trackingPrefixPL}extra-payment-change`
});
export const customLinkNamesCL = Object.freeze({
  AMOUNT_CHANGE: `${trackingPrefixCL}loan-amount-change`,
  PERIOD_CHANGE: `${trackingPrefixCL}payment-period-change`,
  RATE_TYPE_CHANGE: `${trackingPrefixCL}rate-type-change`,
  RATE_CHANGE: `${trackingPrefixCL}interest-rate-change`,
  TERM_CHANGE: `${trackingPrefixCL}loan-term-change`,
  EXTRA_PAYMENT_CHANGE: `${trackingPrefixCL}extra-payment-change`
});

/**
 * getCustomLinkEventName - work out complete custom tracking link event name
 * @param linkEventName {string} - custom link event name
 * @param linkEventValue {string} - value for custom link event
 * @returns {string}
 */
export const getCustomLinkEventName = (
  linkEventName: string,
  linkEventValue?: string = ''
) => {
  const eventValue =
    linkEventValue && linkEventValue.length ? `:${linkEventValue}` : '';
  return `${linkEventName}${eventValue}`;
};
