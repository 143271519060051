import React, { useState } from 'react';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { Box } from '@latitude/box';
import {
  LOAN_TYPES,
  LOAN_PAY_FREQUENCY,
  PL_APPLY_URL,
  CL_APPLY_URL,
  PL_APPLY_CTA_TRACK_ID,
  CL_APPLY_CTA_TRACK_ID
} from '../../utils/constants';
import CalculatorTotals from './CalculatorTotalsKiwibank';
import CalculatorFieldsKiwibank from './CalculatorFieldsKiwibank';
import PLDisclaimerContent from './PersonalLoanDisclaimerContentKb';
import CLDisclaimerContent from './CarLoanDisclaimerContent';
import {
  StyledCalculatorFlex,
  StyledDisclaimerKbBox
} from './LoanCalculator.styled';

const LoanCalculator = ({
  loanType = LOAN_TYPES.PL, // default as PL
  salesMode = false,
  disclaimerContent,
  applyUrl,
  applyCTATrackId,
  applyCTATrackProductId,
  renderCalculatorIntro
}) => {
  const [repaymentAmount, setRepaymentAmount] = useState(0);
  const [totalInterestPaid, setTotalInterestPaid] = useState(0);
  const [totalAmountPaid, setTotalAmountPaid] = useState(0);
  const [repaymentPeriod, setRepaymentPeriod] = useState(
    LOAN_PAY_FREQUENCY.MONTHLY
  );
  const [extraRepayments, setExtraRepayments] = useState(0);
  const [interestSaved, setInterestSaved] = useState(0);
  const [timeSavedYears, setTimeSavedYears] = useState(0);
  const [timeSavedMonths, setTimeSavedMonths] = useState(0);
  const [userInputLoanAmount, setUserInputLoanAmount] = useState(false);
  const [userInputRepaymentYears, setUserInputRepaymentYears] = useState(false);

  // loan type variants
  const defaultApplyUrl =
    loanType === LOAN_TYPES.PL ? PL_APPLY_URL : CL_APPLY_URL;
  const defaultApplyCTATrackId =
    loanType === LOAN_TYPES.PL ? PL_APPLY_CTA_TRACK_ID : CL_APPLY_CTA_TRACK_ID;
  const defaultDisclaimerContent =
    loanType === LOAN_TYPES.PL ? (
      <PLDisclaimerContent />
    ) : (
      <CLDisclaimerContent />
    );

  return (
    <AnalyticsLocationProvider location="Calculate your repayments">
      <Box>
        <StyledCalculatorFlex>
          <CalculatorFieldsKiwibank
            loanType={loanType}
            salesMode={salesMode}
            setRepaymentAmount={setRepaymentAmount}
            setTotalInterestPaid={setTotalInterestPaid}
            setTotalAmountPaid={setTotalAmountPaid}
            repaymentPeriod={repaymentPeriod}
            setRepaymentPeriod={setRepaymentPeriod}
            extraRepayments={extraRepayments}
            setInterestSaved={setInterestSaved}
            setTimeSavedMonths={setTimeSavedMonths}
            setTimeSavedYears={setTimeSavedYears}
            setUserInputLoanAmount={setUserInputLoanAmount}
            setUserInputRepaymentYears={setUserInputRepaymentYears}
          />
          <CalculatorTotals
            loanType={loanType}
            salesMode={salesMode}
            repaymentPeriod={repaymentPeriod}
            repaymentAmount={repaymentAmount}
            totalInterestPaid={totalInterestPaid}
            totalAmountPaid={totalAmountPaid}
            extraRepayments={extraRepayments}
            setExtraRepayments={setExtraRepayments}
            applyUrl={applyUrl || defaultApplyUrl}
            applyCTATrackId={applyCTATrackId || defaultApplyCTATrackId}
            applyCTATrackProductId={applyCTATrackProductId}
            renderIntro={renderCalculatorIntro}
            timeSavedMonths={timeSavedMonths}
            timeSavedYears={timeSavedYears}
            interestSaved={interestSaved}
            userInputLoanAmount={userInputLoanAmount}
            userInputRepaymentYears={userInputRepaymentYears}
          />
        </StyledCalculatorFlex>
        <StyledDisclaimerKbBox>
          {disclaimerContent || defaultDisclaimerContent}
        </StyledDisclaimerKbBox>
      </Box>
    </AnalyticsLocationProvider>
  );
};

export default LoanCalculator;
