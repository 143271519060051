import React, { useState, useContext, useEffect } from 'react';
import { AnalyticsContext, trackEvent } from 'latitude-analytics';

import { Text } from '../Text';
import './_rate-input.scss';
import {
  COLOR,
  MARGIN,
  ALIGN_ITEMS,
  LINE_HEIGHT,
  CALCULATOR_TRACKING,
  FLEX_DIRECTION
} from '../../utils/constants';
import { Box, Flex } from '../Box/Box';
import { numberToPercentage } from '../../utils/helpers';
import { directTrackLink } from '../../utils/analyticsUtil';
import {
  customLinkNamesPL,
  getCustomLinkEventName
} from './LoanCalculator.analytics';

/**
 * trackInterestRateChange
 * @param {number} val - rate number from slider 'onAfterChange' event
 */
const trackInterestRateChange = val => {
  let newRateValue = null;
  if (typeof val === 'number') {
    newRateValue = val.toFixed(2);
  } else if (typeof val === 'string' && !!val.trim().length) {
    newRateValue = val.trim();
  }

  // trigger analytics call
  directTrackLink(
    true,
    'o',
    getCustomLinkEventName(
      customLinkNamesPL.RATE_CHANGE,
      newRateValue === null ? '' : `${newRateValue}%`
    )
  );
};
/**
 * Entering an interest rate with a keyboard, not just the slider (for increased accuracy lm-2648)
 *
 * We added a html <input> to allow manual entery of an interest rate from keyboard as the slider did not allow very granular selection.
 *
 * Initially John Murfett tried using react-number-format as is used in the <CurrencyInput> to enter the loan amount,
 * but this component is limited to three input types: "One of ['text', 'tel', 'password']"
 * (see https://www.npmjs.com/package/react-number-format#props )
 * which limited us to using the `tel` input type field for numbers, which does not include a decimal point on the keyboard.
 *
 * HTML <input>'s inputmode='decimal' formatting (see https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inputmode)
 * was designed for the task we are attemping here, but John could not see a way to get react-number-format to use it.
 * It was noted that react-number-format defaults to using a heavily stlyed and controlled HTML <input> to input it's numbers,
 * so John used the CSS from the <CurrencyInput> (modified it), and a HTML <input> instead of react-number-format.
 *
 * We limit the <input> to 5 characters but do not limit it to a format of 2 digits - decimal point - 2 digits (XX.XX) as suggested by the business.
 * Tracking XX.XX formatting would require tracking the position of the cursor inside the input in react state,
 * and although possible, the number of test cases required for different scenarios rapidly blew out, and could not be developed in the
 * story points allocated at the time of development. If we could use react-number-format, this type of format control would be easier.
 *
 * STATE:
 *
 * As only one UI element can have focus at any time, and the <input> and <slider> fields must have focus to change and hence update state,
 * we update the same parent `interestRate` state using the setInterestRate prop function, without risk of race conditions.
 *
 * As it is possible to enter an invalid rate in the <input>, we keep track of it's value in state `rateHtmlInputText` in this component.
 * If the user enters an invalid interest rate in the <input>, the prop setInterestRate is NOT called, but the local state variable is, and
 * 'Please enter a rate or 7.99% p.a. or more' type error messages are displayed under the <input>
 * If the slider updates the rate, we also update the `rateHtmlInputText` state and reset any <input> field error messages, as the
 * slider can only select valid interest rates (note it is theoretically possible for the front end to be hacked to allow
 * the slider to select invalid values, but do not test for this)
 **/
const InterestRateWidget = ({
  salesMode,
  interestRate,
  setInterestRate,
  minInterestRate,
  maxInterestRate
}) => {
  const [ rateHtmlInputText, setRateHtmlInputText ] = useState(interestRate.toFixed(2));

  // this state tracks last interest rate tracked to prevent multiple
  // tracking call with same value due to slider 'onAfterChange' may
  // fire multiple times with same value
  const [lastInterestRateTracked, setLastInterestRateTracked] = useState(0);

  // throttled functions must be stored as a Ref, otherwise references to
  // the previously called functions are lost on re-render
  // const handleInterestRateBeingChanged = useRef(
  //   _throttle(value => {
  //     setInterestRate(value);
  //     setRateHtmlInputText(value.toFixed(2));
  //   }, 200)
  // );

  // rateHtmlInputError displays 'Please enter a rate of 7.99% p.a. or more' type messages when rate is outside permitted range
  const [ rateHtmlInputError, setRateHtmlInputError ] = useState('');

  // resetHtmlInput sets the value of the <input> from the a few places including the slider chanage and max/min interest rate changes - and clears any errors.
  function resetHtmlInput(value=null) {
    // note no validation on value - it assumes it is null or a valid interest rate, which is OK as it is only called in this code.
    if (value===null) value = interestRate;
    setRateHtmlInputText(value.toFixed(2));
    // we use an empty string to prevent 'null' or 'false' displaying in the error display field.
    setRateHtmlInputError('');
  }

  /**
   * When we change between secured and unsecured, if the rate in the text box is not valid, we force it to be the value in the slider.
   * This will cover the edge case where a rate entered in the rate input field is invalid for a secured rate but valid for an unsecured rate.
   * This can occur because the max interest rate for an unsecured loan is higher than for a secured loan.
   *
   * We use a change in the min and/or max interest rates as a proxy for a change in secured/unsecured - as `secured` is not a prop on this compomnent
   */
  useEffect( ()=> {
      resetHtmlInput();
    },
    [ maxInterestRate, minInterestRate ]
  );

  // context for Tealium tracking implmentation
  const [analytics] = useContext(AnalyticsContext);

  /**
   * Delayed Sync between slider and input interest rate values
   *
   * We found during testing that the RanegSlider can sometimes fall out of sync with the <input> field.
   * This is more common when you rapidly move the slider left and right.
   *
   * <RangeSlider> is a wrapper around a <Slider> from npm rc-slider module, which has these two important props:
   *   onChange - triggered while the value of Slider is changing
   *   onAfterChange - triggered when ontouchend or onmouseup is triggered (so the value is fixed)
   */

  function interestRateChanged(value, source = 'slider') {
    // only fire tracking when value has actually changed in the slider (on mouse up or touch end)
    // slider 'onAfterChange' may fire and call interestRateChanged with same values
    if (source === 'slider')  resetHtmlInput(value);
    if (!salesMode && value !== lastInterestRateTracked) {
      trackInterestRateChange(value);
      setLastInterestRateTracked(value);
    }

    // Tealium tracking implementation
    if (trackEvent) {
      trackEvent(analytics, {
        category: CALCULATOR_TRACKING.CATEGORY,
        action: CALCULATOR_TRACKING.ACTION,
        label: 'interest-rate',
        location: CALCULATOR_TRACKING.LOCATION,
        value: numberToPercentage(value)
      });
    }
  }
  function rateHtmlInputChange(value) {

    setRateHtmlInputText(value);
    // validate the entered rate:
    const newRate = Number.parseFloat(value);
    if (Number.isNaN(newRate)) {
      setRateHtmlInputError(`Please enter a rate of ${minInterestRate}% p.a. or more`);
      return;
    }
    if (newRate < minInterestRate) {
      setRateHtmlInputError(`Please enter a rate of ${minInterestRate}% p.a. or more`);
      return;
    }
    if (newRate > maxInterestRate) {
      setRateHtmlInputError(`Please enter a rate of ${maxInterestRate}% p.a. or less`);
      return;
    }
    // interest rate is valid.
    setRateHtmlInputError('');
    // we know the rate is a number in the valid range, so let's round it to two decimal places:
    const roundedNewRate = Math.round(newRate*100)/100;
    if (roundedNewRate !== interestRate) {
      setInterestRate(roundedNewRate);
      return interestRateChanged(roundedNewRate, 'input');
    }
  }

  const showError = (rateHtmlInputError !== '')
  const extraClasses = showError ? ' error' : '';
  const componentNameSnakeCase = 'interest-rate-widget'
  return (
    <Box>
      {/* eslint-disable-next-line */}
      <Flex flexDirection={FLEX_DIRECTION.COLUMN} alignItems={ALIGN_ITEMS.FLEX_START}>
        <Text
          marginBottom={MARGIN.M8}
        >
          At an interest rate of&nbsp;
        </Text>
        <div className={`${componentNameSnakeCase}__wrapper input-wrap`}>
          <input
            className={`${componentNameSnakeCase}__field styled-input-wrap__input interestRate ${extraClasses} kiwibank`}
            id={`${componentNameSnakeCase}RateHtmlInput`}
            maxLength={5}
            value={rateHtmlInputText}
            onChange={(e)=>{ rateHtmlInputChange(e.target.value) }}
            type='text'
            inputMode='decimal'
            color='#000 !important'
          />
          <span className={`${componentNameSnakeCase}__field-suffix`}  css='color:#000 !important' >%</span>
          <Text css='padding-left: 15px;'>
            p.a.
          </Text>
        </div>
        <Text
          lineHeight={LINE_HEIGHT.STANDARD.NORMAL}
          marginBottom={MARGIN.M4}
          marginTop={MARGIN.M4}
          color={COLOR.ERROR}
        >{rateHtmlInputError || <div>&nbsp;</div>}</Text>
      </Flex>
    </Box>
  );
};

export default InterestRateWidget;
