import React from 'react';
import { COLOR, DISPLAY } from '@latitude/core/utils/constants';
import { ListNumbered } from '@latitude/list-numbered';
import { ListNumberedWrapper } from '@latitude/confetti-framed-container/ConfettiFramedContainer';
import { ConfettiFramedContainer } from '@latitude/confetti-framed-container/';
import data from '../../data/json/how-to-apply.json';

const HowToApplySection = props => (
  <ConfettiFramedContainer
    id="how-to-apply"
    heading={props.title || data.title}
    minHeight="240px"
    innerContainerMaxWidth="730px"
    hasPlainBgColor={COLOR.GREY6}
    framedContainerTheme={{
      frameColor: COLOR.GREY12,
      contentBorderColor: DISPLAY.NONE
    }}
  >
    <ListNumberedWrapper>
      <ListNumbered
        data={props.data || data.HowToApplyKiwibank}
        viewStyle="compact"
        separator="none"
        counterBgColor={COLOR.BLACK}
      />
    </ListNumberedWrapper>
  </ConfettiFramedContainer>
);

export default HowToApplySection;
