// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Text, Bold } from '@latitude/text';
import {
  ALIGN,
  BREAKPOINT,
  COLOR,
  FONT_SIZE,
  MARGIN
} from '@latitude/core/utils/constants';

/**
 * CL Disclaimer content
 */
const StyledDisclaimerText = styled(Text)`
  color: ${COLOR.WHITE};
  margin-bottom: ${MARGIN.M8};

  &:last-child,
  &:last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    color: ${COLOR.BLACK};
  }
`;

const CLDisclaimerContent = () => (
  <Fragment>
    <StyledDisclaimerText fontSize={FONT_SIZE.NORMAL} align={ALIGN.CENTER}>
      <Bold>Calculator Assumptions</Bold>
      <br />
      The repayment amount shown using this calculator is an estimate only. The
      results from this calculator should be used as an indication only, and do
      not represent either a quote or pre-qualification or approval for a loan.
      This calculator should only be used as a guide to see how personal loan
      repayments can vary when you change the loan amount, interest rate, and
      loan term. The interest saved and time saved is an estimate only and is
      based on paying the extra monthly amount for the full term. Total amount
      paid includes the loan amount, interest and a $240 establishment fee.
    </StyledDisclaimerText>
  </Fragment>
);

export default CLDisclaimerContent;
