import React, { useState, useEffect, useContext } from 'react';
import { AnalyticsContext, trackEvent } from 'latitude-analytics';
import ReactHtmlParser from 'react-html-parser';
import { debounce as _debounce } from 'lodash';
import styled from 'styled-components';
import { Box, Flex } from '@latitude/box';
// import { Link } from '@latitude/link';
import { Vertical } from '@latitude/spacing';
import { Tel } from '@latitude/tel';
import { Text, Bold } from '@latitude/text';
import {
  BREAKPOINT,
  COLOR,
  FONT_WEIGHT,
  JUSTIFY_CONTENT,
  MARGIN
} from '@latitude/core/utils/constants';
import { formatAsCurrency } from '../../utils';
import { getRepaymentFigures } from '../../utils/loanCalculatorUtil';
import RadioBoxes from '../RadioBoxes/RadioBoxesKiwibank';
import Select from '../Select/Select';
import CurrencyInput from '../CurrencyInput/CurrencyInput';
import InterestRateWidget from './InterestRateWidgetKiwibank';
import {
  LOAN_TYPES,
  LOAN_PAY_FREQUENCY,
  PL_MINIMUM_AMOUNT,
  CL_MINIMUM_AMOUNT,
  PL_MAXIMUM_AMOUNT,
  CL_MAXIMUM_AMOUNT,
  PL_SETTLEMENT_FEE_KB,
  CL_SETTLEMENT_FEE,
  PL_MAX_LOAN_TERM,
  CL_MAX_LOAN_TERM,
  PL_DEFAULT_LOAN_TERM,
  CL_DEFAULT_LOAN_TERM,
  PL_KIWIBANKHOME_MIN_IR,
  PL_KIWIBANKHOME_MAX_IR,
  PL_KIWIBANK_MIN_IR,
  PL_KIWIBANK_MAX_IR,
  CL_SECURED_MIN_IR,
  PL_TEL,
  CL_TEL,
  CALCULATOR_TRACKING
} from '../../utils/constants';
import {
  directTrackLink,
  normaliseForAnalytics
} from '../../utils/analyticsUtil';
import {
  customLinkNamesPL,
  customLinkNamesCL,
  getCustomLinkEventName
} from './LoanCalculator.analytics';
// import Modal from '../Modal/Modal';

/**
 * Custom styles
 */

const StyledInputsBox = styled(Box)`
  width: 100%;
  @media (min-width: ${BREAKPOINT.LG}) {
    width: 50%;
  }
`;

const StyledLoanAmountBox = styled(Box)`
  margin-right: 0;
  width: 100%;

  @media (min-width: ${BREAKPOINT.MD}) {
    margin-right: ${MARGIN.M16};
    width: 50%;
  }
`;

const StyledLoanPeriodBox = styled(Box)`
  margin-top: ${MARGIN.M32};
  width: 100%;

  @media (min-width: ${BREAKPOINT.MD}) {
    margin-top: 0;
    width: 50%;
  }
`;

const StyledLoanTermBox = styled(Box)`
  width: 100%;
  margin-top: -32px !important;
`;

// const StyledSvg = styled(SvgInline)`
//   width: 20px;
//   height: 20px;
//   position: relative;
//   top: 5px;
//   margin-left: 5px;
// `;

const StyledFlex = styled(Flex)`
  flex-wrap: wrap;

  @media (min-width: ${BREAKPOINT.MD}) {
    flex-wrap: nowrap;
  }
`;

/**
 * validateLoanAmount
 *
 * Notes:
 * - error will stop calculation
 * - warning will still calculate
 * - both error and warning will display validation message
 *
 * @param {number} amountValue - loan amount for validation
 * @param {string} loanType - loan type name value
 * @returns {object} validation result
 */
const validateLoanAmount = (amountValue, loanType) => {
  let isError;
  let isWarning;
  let message = '';

  // determine validation loan amounts for product
  const minAmount =
    loanType === LOAN_TYPES.PL ? PL_MINIMUM_AMOUNT : CL_MINIMUM_AMOUNT;
  const maxAmount =
    loanType === LOAN_TYPES.PL ? PL_MAXIMUM_AMOUNT : CL_MAXIMUM_AMOUNT;

  // determine validation messages
  const tel = loanType === LOAN_TYPES.PL ? PL_TEL : CL_TEL;
  const hours =
    'Mon&nbsp;-&nbsp;Fri:&nbsp;8am&nbsp;-&nbsp;7pm, Weekends: 8.30am&nbsp;-&nbsp;5pm (NZST)';

  // validate input amount value
  if (!amountValue || amountValue < minAmount) {
    isError = true;
    isWarning = false;
    message = `Loan amount must be at least ${formatAsCurrency(minAmount)}`;
  } else if (amountValue > maxAmount) {
    isError = false;
    isWarning = true;
    message = (
      <Text color={COLOR.TEXT_ERROR} fontWeight={FONT_WEIGHT.NORMAL}>
        {`For loan amounts more than ${formatAsCurrency(
          maxAmount
        )} please call us on `}
        <Tel color={COLOR.TEXT_ERROR} no={tel} />, {ReactHtmlParser(hours)}
      </Text>
    );
  } else {
    isError = false;
    isWarning = false;
  }

  return {
    isError,
    isWarning,
    message
  };
};

// loan amount analytics tracking method, trying to only capture
// the final amount input value by having a long debounce duration
const debouncedTrackAmountChange = _debounce(
  (e, validationResult, loanType = LOAN_TYPES.PL, salesMode = false) => {
    const newAmountValue = e.value; // use 'value' for string type
    const { isError, isWarning } = validationResult;

    // loan type variants
    const customLinkNames =
      loanType === LOAN_TYPES.PL ? customLinkNamesPL : customLinkNamesCL;

    let eventValue;
    if (newAmountValue && isError) {
      eventValue = `error:${newAmountValue}`;
    } else if (newAmountValue && isWarning) {
      eventValue = `warning:${newAmountValue}`;
    } else if (newAmountValue) {
      eventValue = newAmountValue;
    } else {
      eventValue = '';
    }

    // trigger analytics call
    // eslint-disable-next-line no-unused-expressions
    !salesMode &&
      eventValue.length &&
      directTrackLink(
        true,
        'o',
        getCustomLinkEventName(customLinkNames.AMOUNT_CHANGE, eventValue)
      );
  },
  1600
);

// Generate loan term select value data list
const generateLoanTermSelectValues = (maxTerm, increment) => {
  const values = [];
  for (let year = increment; year <= maxTerm; year += increment) {
    values.push({
      text: `${year} year${year !== 1 ? 's' : ''}`,
      value: year
    });
  }
  return values;
};
// only generated once due to data is static
const loanTermSelectValuesPL = generateLoanTermSelectValues(
  PL_MAX_LOAN_TERM,
  1
);
const loanTermSelectValuesCL = generateLoanTermSelectValues(
  CL_MAX_LOAN_TERM,
  1
);

const CalculatorFieldsKiwibank = ({
  loanType,
  salesMode,
  setRepaymentAmount,
  setTotalInterestPaid,
  setTotalAmountPaid,
  repaymentPeriod,
  setRepaymentPeriod,
  extraRepayments,
  setInterestSaved,
  setTimeSavedMonths,
  setTimeSavedYears,
  setUserInputLoanAmount,
  setUserInputRepaymentYears
}) => {
  // loan type variants
  const isLoanTypePL = loanType === LOAN_TYPES.PL;
  const loanTermSelectValues = isLoanTypePL
    ? loanTermSelectValuesPL
    : loanTermSelectValuesCL;
  const defaultMinIR = isLoanTypePL ? PL_KIWIBANKHOME_MIN_IR : CL_SECURED_MIN_IR;
  const toggleDefaults = !isLoanTypePL; // business requirement to have diff default for PL calculator
  const defaultMinLoanAmount = isLoanTypePL
    ? PL_MINIMUM_AMOUNT
    : CL_MINIMUM_AMOUNT;
  const defaultLoanTerm = isLoanTypePL
    ? PL_DEFAULT_LOAN_TERM
    : CL_DEFAULT_LOAN_TERM;
  const customLinkNames = isLoanTypePL ? customLinkNamesPL : customLinkNamesCL;
  const repayPeriodId = `${isLoanTypePL ? 'pl' : 'cl'}-calc-repaymentPeriod`;
  const repayYearsId = `${isLoanTypePL ? 'pl' : 'cl'}-calc-repaymentYears`;

  // hooks and states
  const [loanAmount, setLoanAmount] = useState(defaultMinLoanAmount);
  const [hasError, setHasError] = useState(false);
  const [hasWarning, setHasWarning] = useState(false);
  const [amountValidationMessage, setAmountValidationMessage] = useState('');
  const [repaymentYears, setRepaymentYears] = useState(defaultLoanTerm);
  const [interestRate, setInterestRate] = useState(defaultMinIR);
  // const [showModal, setShowModal] = useState(false);
  const [isSecured, setIsSecured] = useState(toggleDefaults);

  const minInterestRate = isSecured ? PL_KIWIBANK_MIN_IR : PL_KIWIBANKHOME_MIN_IR;
  const maxInterestRate = isSecured ? PL_KIWIBANK_MAX_IR : PL_KIWIBANKHOME_MAX_IR;

  const minComparisonRate = null; // N/A for NZ calculator
  const maxComparisonRate = null; // N/A for NZ calculator

  const [analytics] = useContext(AnalyticsContext);

  // event handlers
  const handleRepaymentPeriodOnChange = e => {
    const newPeriodValue = e.target.value;
    setRepaymentPeriod(newPeriodValue);

    // trigger analytics call
    // eslint-disable-next-line no-unused-expressions
    !salesMode &&
      directTrackLink(
        true,
        'o',
        getCustomLinkEventName(
          customLinkNames.PERIOD_CHANGE,
          normaliseForAnalytics(newPeriodValue)
        )
      );

    /*
     * Tealium tracking
     */
    if (trackEvent) {
      trackEvent(analytics, {
        category: CALCULATOR_TRACKING.CATEGORY,
        action: CALCULATOR_TRACKING.ACTION,
        label: 'With repayments every',
        location: CALCULATOR_TRACKING.LOCATION,
        value: e.target.options[e.target.selectedIndex].text
      });
    }
  };

  const handleRateTypeOnChange = e => {
    const secured = e.target.value === 'true';

    if (secured) {
      setInterestRate(PL_KIWIBANK_MIN_IR);
    } else {
      setInterestRate(PL_KIWIBANKHOME_MIN_IR);
    }
    setIsSecured(secured);

    const eventValue = secured ? 'secured' : 'unsecured';
    // trigger analytics call
    // eslint-disable-next-line no-unused-expressions
    !salesMode &&
      directTrackLink(
        true,
        'o',
        getCustomLinkEventName(customLinkNames.RATE_TYPE_CHANGE, eventValue)
      );

    /*
     * Tealium tracking
     */
    if (trackEvent) {
      trackEvent(analytics, {
        category: CALCULATOR_TRACKING.CATEGORY,
        action: CALCULATOR_TRACKING.ACTION,
        label: 'Secured or unsecured',
        location: CALCULATOR_TRACKING.LOCATION,
        value: eventValue
      });
    }
  };

  const handleTermOnChange = e => {
    const newTermValue = e.target.value;
    sessionStorage.setItem('repaymentPeriod', newTermValue);
    setRepaymentYears(parseFloat(newTermValue));
    setUserInputRepaymentYears(Number(newTermValue));

    // trigger analytics call
    // eslint-disable-next-line no-unused-expressions
    !salesMode &&
      directTrackLink(
        true,
        'o',
        getCustomLinkEventName(customLinkNames.TERM_CHANGE, newTermValue)
      );

    /*
     * Tealium tracking
     */
    if (trackEvent) {
      trackEvent(analytics, {
        category: CALCULATOR_TRACKING.CATEGORY,
        action: CALCULATOR_TRACKING.ACTION,
        label: 'And pay it over',
        location: CALCULATOR_TRACKING.LOCATION,
        value: newTermValue
      });
    }
  };

  const setTimeSavedValues = timeSaved => {
    // average amount of weeks in a month
    const weekToMonthModifier = 4.35;

    // calculate total time saved in months based on repayment frequency
    let timeSavedInMonths = timeSaved;
    if (repaymentPeriod === LOAN_PAY_FREQUENCY.FORTNIGHTLY) {
      timeSavedInMonths = (timeSaved / weekToMonthModifier) * 2;
    } else if (repaymentPeriod === LOAN_PAY_FREQUENCY.WEEKLY) {
      timeSavedInMonths = timeSaved / weekToMonthModifier;
    }

    // update time saved years and months to absolute rounded value
    timeSavedInMonths = Math.abs(Math.round(timeSavedInMonths));

    setTimeSavedYears(Math.floor(timeSavedInMonths / 12));
    setTimeSavedMonths(timeSavedInMonths % 12);
  };

  useEffect(() => {
    // loan type variants
    const settlementFee = isLoanTypePL ? PL_SETTLEMENT_FEE_KB : CL_SETTLEMENT_FEE;
    // const monthlyFee = isLoanTypePL ? PL_MONTHLY_FEE : CL_MONTHLY_FEE;
    const monthlyFee = 0;
    // stop calculation ONLY when we have invalid input or error
    // should still calculate when having validation warning
    if (loanAmount && loanAmount !== 0 && !hasError) {
      const principle = loanAmount + settlementFee;
      const roundedInterestRate = parseFloat(interestRate.toFixed(2));

      const {
        repayment,
        totalInterest,
        totalAmountPaid,
        timeSavedByExtra,
        interestSavedByExtra
      } = getRepaymentFigures(
        principle,
        roundedInterestRate,
        repaymentYears,
        repaymentPeriod,
        monthlyFee,
        extraRepayments,
        true
      );

      setRepaymentAmount(repayment);
      setTotalInterestPaid(totalInterest);
      setTotalAmountPaid(totalAmountPaid);
      setInterestSaved(interestSavedByExtra);
      setTimeSavedValues(timeSavedByExtra);

      // console.log('principle', principle);
      // console.log('roundedInterestRate', roundedInterestRate);
      // console.log('repaymentYears', repaymentYears);
      // console.log('repaymentPeriod', repaymentPeriod);
      // console.log('extraRepayments', extraRepayments);
      // console.log('isSecured', isSecured);
    }
  }, [
    loanAmount,
    isSecured,
    repaymentYears,
    repaymentPeriod,
    interestRate,
    extraRepayments
  ]);

  // work out loan term select label text
  const loanTermSelectLabel = (
    <Text>
      And pay it over{' '}
      <Bold>
        {repaymentYears} year
        {parseFloat(repaymentYears) === 1 ? '' : 's'}
      </Bold>
    </Text>
  );

  return (
    <StyledInputsBox>
      <Vertical spacing={MARGIN.M32}>
        <StyledFlex justifyContent={JUSTIFY_CONTENT.SPACE_BETWEEN}>
          <StyledLoanAmountBox width="50%">
            <CurrencyInput
              type="tel"
              labelText={CALCULATOR_TRACKING.LABEL}
              maxLength={7}
              prefix="$"
              name="loanAmount"
              value={loanAmount}
              errorStatus={hasError || hasWarning}
              showError={hasError || hasWarning}
              onValueChange={e => {
                // validate and set new amount
                const validationResult = validateLoanAmount(
                  e.floatValue,
                  loanType
                );
                setHasError(validationResult.isError);
                setHasWarning(validationResult.isWarning);
                setAmountValidationMessage(validationResult.message);
                setLoanAmount(e.floatValue);
                setUserInputLoanAmount(e.floatValue);
                sessionStorage.setItem('loanAmount', e.floatValue);
                // any operations that needs to be debounced
                debouncedTrackAmountChange(
                  e,
                  validationResult,
                  loanType,
                  salesMode
                );
              }}
              kiwibank
              showErrorMessage={amountValidationMessage}
            />
          </StyledLoanAmountBox>
          <StyledLoanPeriodBox width="50%">
            <Select
              id={repayPeriodId}
              label={<Text>With repayments every</Text>}
              name="repaymentPeriod"
              selectedValue={repaymentPeriod}
              onChange={handleRepaymentPeriodOnChange}
              onBlur={() => {}}
              values={[
                { text: 'Week', value: LOAN_PAY_FREQUENCY.WEEKLY },
                {
                  text: 'Fortnight',
                  value: LOAN_PAY_FREQUENCY.FORTNIGHTLY
                },
                { text: 'Month', value: LOAN_PAY_FREQUENCY.MONTHLY }
              ]}
            />
          </StyledLoanPeriodBox>
        </StyledFlex>

        <Box>
          <RadioBoxes
            // labelText={
            //   <Text>
            //     Secured or unsecured?
            //     <Link
            //       href="#"
            //       noStyle
            //       onClick={e => {
            //         setShowModal(true);
            //         e.preventDefault();
            //       }}
            //       trackEventData={{
            //         category: CALCULATOR_TRACKING.CATEGORY,
            //         action: CALCULATOR_TRACKING.ACTION,
            //         label: 'i - secured or unsecured'
            //       }}
            //     >
            //       <StyledSvg name="info-block-outline" />
            //     </Link>
            //   </Text>
            // }
            name="repaymentsYears"
            onClick={handleRateTypeOnChange}
            values={[
              { value: true, text: 'Standard Personal Loan customers' },
              { value: false, text: 'Kiwibank Home Loan customers' }
            ]}
            selectedValue={isSecured}
            equalWidths
          />
          {/* <Modal
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            title="Secured vs Unsecured loan?"
            content="When shopping around or applying for a personal loan, one decision you'll need to make is whether you want a secured or unsecured loan. Taking out a secured loan means you're able to provide an asset like your car, for Gem to secure your loan against. An advantage of taking out a secured loan is that you may be eligible for a lower interest rate. If you don't have a suitable asset to secure your loan against, you may decide to apply for an unsecured loan."
            ariaHideApp={false}
          /> */}
        </Box>

        <Box>
          <InterestRateWidget
            loanType={loanType}
            salesMode={salesMode}
            interestRate={interestRate}
            setInterestRate={setInterestRate}
            minInterestRate={minInterestRate}
            minComparisonRate={minComparisonRate}
            maxInterestRate={maxInterestRate}
            maxComparisonRate={maxComparisonRate}
          />
        </Box>
        <StyledLoanTermBox>
          <Select
            id={repayYearsId}
            name="repaymentYears"
            label={loanTermSelectLabel}
            selectedValue={repaymentYears}
            values={loanTermSelectValues}
            onChange={handleTermOnChange}
            onBlur={() => {}}
          />
        </StyledLoanTermBox>
      </Vertical>
    </StyledInputsBox>
  );
};

export default CalculatorFieldsKiwibank;
