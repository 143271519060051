import React from 'react';

export default WrappedComponent =>
  class StickyStatePageHOC extends React.Component {
    state = { isNavSticky: false };

    // Handle the change of the "Sticky Navigation" state
    handleStickyNavStateChange = isNavSticky =>
      this.setState(prevState => {
        if (prevState.isNavSticky !== isNavSticky) {
          return { ...prevState, isNavSticky };
        }
        return prevState;
      });

    render() {
      return (
        <WrappedComponent
          isNavSticky={this.state.isNavSticky}
          handleStickyNavStateChange={this.handleStickyNavStateChange}
          {...this.props}
        />
      );
    }
  };
